import React from 'react'
import CountUp from 'react-countup';
import i1 from "../../Assets/Services/i1.png"
import i2 from "../../Assets/Services/i2.png"
import i3 from "../../Assets/Services/i3.png"
import i4 from "../../Assets/Services/i4.png"
import banner2 from "../../Assets/Services/banner2.mp4"
const Amenity = () => {
  return (
    <div>
      <section className="section">
  <div className="w-layout-blockcontainer base-container w-container">
    <div className="row">
      <div className="col-6"></div>
    </div>
    
    <div className="w-layout-grid our-services-grid">
      <div
        data-w-id="3307da7b-23c0-4f87-ba57-62e5e3b31ed4"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="service-card"
      >
        <div className="service-image-cont">
          <img
            src={i1}
            loading="lazy"
            alt="service icon"
            className="servise-icon"
          />
        </div>
        <h5 className="text-primary-color" style={{textAlign:"center"}}>Personalized Packages</h5>
        <p className="service-card-paragraph">
         
Custom charter packages for private jets and helicopters, tailored to your desires.
          <br />
        </p>
      </div>
      <div
        data-w-id="29984297-1eec-bf2b-eea9-912a7340a032"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="service-card"
      >
        <div className="service-image-cont">
          <img
            src={i2}
            loading="lazy"
            alt="service icon"
            className="servise-icon"
          />
        </div>
        <h5 className="text-primary-color" style={{textAlign:"center"}}>Event Planning Assistance</h5>
        <p className="service-card-paragraph">
          
Planning an elite airborne event? Our team ensures a perfect celebration on private jets or helicopters.
          <br />
        </p>
      </div>
      <div
        data-w-id="b77c63ca-4698-0f2c-c1ef-c32fa798bc1d"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="service-card"
      >
        <div className="service-image-cont">
          <img
            src={i3}
            loading="lazy"
            alt="service icon"
            className="servise-icon"
          />
        </div>
        <h5 className="text-primary-color" style={{textAlign:"center"}}>Flexible Booking Options</h5>
        <p className="service-card-paragraph">
        Our user-friendly platform streamlines booking and customization for private jets and helicopters.
          <br />
        </p>
      </div>
      <div
        data-w-id="79d2dfdd-fe7f-7d85-e138-f4f4e6d11816"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="service-card"
      >
        <div className="service-image-cont">
          <img
            src={i4}
            loading="lazy"
            alt="service icon"
            className="servise-icon"
          />
        </div>
        <h5 className="text-primary-color" style={{textAlign:"center"}}>Unforgettable Experiences</h5>
        <p className="service-card-paragraph">
         
Whether you're yearning for serenity, excitement, or a fusion of both, explore our private jet and helicopter charters.
          <br />
        </p>
      </div>
    </div>
  </div>
</section>
<section className="section without-space">
  <div className="w-layout-blockcontainer base-container w-container">
    <div
      data-w-id="01c0c8ff-30b7-42c9-db2f-1ac6029aa115"
      style={{
        opacity: 1,
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
      className="video-wrapper"
    >
      <div
        data-poster-url="https://assets-global.website-files.com/651f1b0f35b75e7485f80693/65253c59fe26d688b193e5d0_Yachta-poster-00001.jpg"
        data-video-urls="https://assets-global.website-files.com/651f1b0f35b75e7485f80693/65253c59fe26d688b193e5d0_Yachta-transcode.mp4,https://assets-global.website-files.com/651f1b0f35b75e7485f80693/65253c59fe26d688b193e5d0_Yachta-transcode.webm"
        data-autoplay="true"
        data-loop="true"
        data-wf-ignore="true"
        className="background-video w-background-video w-background-video-atom"
      >
        <video
         autoPlay loop muted playsInline
          id="01c0c8ff-30b7-42c9-db2f-1ac6029aa116-video"
          
          style={{
            backgroundImage:
              {banner2}
          }}
          
          data-wf-ignore="true"
          data-object-fit="cover"
        >
          <source src={banner2} type="video/mp4" />
        </video>
      </div>
      <div
        data-w-id="01c0c8ff-30b7-42c9-db2f-1ac6029aa117"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="description-video-block"
      >
        <h2 className="white-text">Contemporary and premier private jet and helicopter fleet</h2>
        <div className="subheading">
          
Our private jets and helicopters are fitted with cutting-edge amenities, guaranteeing a luxurious and comfortable experience.
        </div>
      
        <a
          href="/Contectus"
          className="primary-button-yellow margin-top-medium w-button"
        >
          Book Now
        </a>
       
       
      </div>
    </div>
  </div>
</section>
<div className="section-negative green-bgr">
  <div className="base-container w-container">
    <div className="top-block-content">
      <div
        data-w-id="ca357152-f17c-ace2-984e-9479095d80ff"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="left-content"
      >
        <h2 className="white-text text-left">A range waiting to be discover</h2>
        <p className="white-text text-left">
        
At Aerofoil Aviation, we are more than a team. we are a crew united by a shared love for the skies and a dedication to providing unparalleled experiences. Join us in the open skies, where our team transforms your aviation dreams into reality. Whether it's soaring in private jets or hovering in helicopters, we're here to make your aerial aspirations come true.
        </p>
        <div style={{display:"flex"}}>
          <div>
          <a
          href="/Servicees"
          className="secondary-button-white margin-top-medium w-button"
        >
          Our Facility
        </a>
          </div>
       
        </div>
        
      </div>
      <div className="right-content">
        <div
          id="w-node-ca357152-f17c-ace2-984e-9479095d810b-85f806fb"
          data-w-id="ca357152-f17c-ace2-984e-9479095d810b"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="row-content"
        >
          <div className="counter-block">
            <div className="number"><CountUp end={10} /></div>
            <div className="white-text">Clients</div>
          </div>
          <p className="text-white paragraph-large text-left">
          With years of aviation expertise and an intimate knowledge of the skies
          </p>
        </div>
        <div
          id="w-node-ca357152-f17c-ace2-984e-9479095d8113-85f806fb"
          data-w-id="ca357152-f17c-ace2-984e-9479095d8113"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="row-content"
        >
          <div className="counter-block">
            <div className="number"><CountUp end={10} /></div>
            <div className="white-text">Private Jet </div>
          </div>
          <p className="text-white paragraph-large text-left text-left">

          Their boundless creativity transforms your aviation dreams into reality, meticulously curating every detail to perfection, whether it's for private jets or helicopters.
          </p>
        </div>
        <div
          id="w-node-_41fd8fb8-0f0e-2a2b-db42-afbe5379bc03-85f806fb"
          data-w-id="41fd8fb8-0f0e-2a2b-db42-afbe5379bc03"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="row-content"
        >
          <div className="counter-block">
            <div className="number"><CountUp end={10} /></div>
            <div className="white-text">Helicpoter</div>
          </div>
          <p className="text-white paragraph-large text-left">
          Embark on a remarkable journey with us, where our elite team is poised to elevate your experience to new heights, whether aboard private jets or helicopters. Your extraordinary adventure awaits.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Amenity
