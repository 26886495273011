import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import a from "../../Assets/a.png";
import b from "../../Assets/f.png";
import c from "../../Assets/c.png";
import d from "../../Assets/d.png";
import ab from "../../Assets/ab.png"
// import required modules
import { Navigation, Pagination } from "swiper/modules";
const Planesecton = () => {
  return (
    <div>
      <section className="section dark-background">
        <div className="w-layout-blockcontainer base-container w-container">
          <div
            data-w-id="2dd6ae77-4ca4-2b60-35fa-dacc0e9a6237"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="section-title-wrapper"
          >
            <h6 className="section-subtitle text-white">private jet &amp; Helicopter</h6>
            <h2 className="text-white-color">Across the State</h2>
          </div>
        </div>
        <div
          data-w-id="e16dd1a2-a36c-9952-e1ba-73527fdf802f"
          className="slider-wrapper"
        >
          <div
            data-delay={10}
            data-animation="slide"
            className="projects-card-slider w-slider"
            data-autoplay="true"
            data-easing="ease-out-expo"
            data-hide-arrows="false"
            data-disable-swipe="false"
            data-autoplay-limit={1}
            data-nav-spacing={3}
            data-duration={1800}
            data-infinite="true"
            role="region"
            aria-label="carousel"
          >
            <Swiper
              // navigation={{
              //   prevEl: ".slide-arrow-left",
              //   nextEl: ".slide-arrow-right",
              // }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[ Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div
                  className="projects-mask w-slider-mask"
                  id="w-slider-mask-1"
                >
                  <div
                    className="project-slide w-slide"
                    aria-label="1 of 6"
                    role="group"
                    aria-hidden="true"
                  >
                    <div className="w-dyn-list" aria-hidden="true">
                      <div
                        role="list"
                        className="w-dyn-items"
                        aria-hidden="true"
                      >
                        <div
                          role="listitem"
                          className="w-dyn-item"
                          aria-hidden="true"
                        >
                          <div className="yacht-content" aria-hidden="true">
                            <a
                              href="#"
                              className="project-card-wrapper second w-inline-block"
                            >
                              <img
                                alt="yacht"
                                loading="lazy"
                                src={a}
                                sizes="(max-width: 479px) 85vw, (max-width: 767px) 90vw, (max-width: 991px) 80vw, (max-width: 1919px) 50vw, 900px"
                                srcSet={a}
                                className="image-yacht second"
                              />
                              <div className="background-radius" />
                            </a>

                            <div className="project-content" aria-hidden="true">
                              <div
                                id="w-node-_56863fcf-d9e4-ddc3-df1b-97bfb788b6c4-85f806fb"
                                className="project-card-bottom"
                                aria-hidden="true"
                              >
                                <a
                                  id="w-node-_23b1c14a-44ef-ad84-af10-d3a42fbad744-85f806fb"
                                  href="/yachts-boats/azure-odyssey"
                                  className="w-inline-block"
                                  tabIndex={-1}
                                  aria-hidden="true"
                                >
                                  <h3
                                    id="w-node-_56863fcf-d9e4-ddc3-df1b-97bfb788b6c8-85f806fb"
                                    className="heading-hover"
                                    aria-hidden="true"
                                  >
                                  Aircraft Charters
                                  </h3>
                                </a>
                                <div
                                  id="w-node-_56863fcf-d9e4-ddc3-df1b-97bfb788b6c5-85f806fb"
                                  className="text-content"
                                  aria-hidden="true"
                                >
                                  <p
                                    className="subtitle-category"
                                    aria-hidden="true"
                                  >
                                    
Fly high with Skyward Odyssey, your azure-hued charter for unparalleled adventures.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="projects-mask w-slider-mask"
                  id="w-slider-mask-1"
                >
                  <div
                    className="project-slide w-slide"
                    aria-label="1 of 6"
                    role="group"
                    aria-hidden="true"
                  >
                    <div className="w-dyn-list" aria-hidden="true">
                      <div
                        role="list"
                        className="w-dyn-items"
                        aria-hidden="true"
                      >
                        <div
                          role="listitem"
                          className="w-dyn-item"
                          aria-hidden="true"
                        >
                          <div className="yacht-content" aria-hidden="true">
                            <a
                              href="#"
                              className="project-card-wrapper second w-inline-block"
                            >
                              <img
                                alt="yacht"
                                loading="lazy"
                                src={b}
                                sizes="(max-width: 479px) 85vw, (max-width: 767px) 90vw, (max-width: 991px) 80vw, (max-width: 1919px) 50vw, 900px"
                                srcSet={b}
                                className="image-yacht second"
                              />
                              <div className="background-radius" />
                            </a>

                            <div className="project-content" aria-hidden="true">
                              <div
                                id="w-node-_56863fcf-d9e4-ddc3-df1b-97bfb788b6c4-85f806fb"
                                className="project-card-bottom"
                                aria-hidden="true"
                              >
                                <a
                                  id="w-node-_23b1c14a-44ef-ad84-af10-d3a42fbad744-85f806fb"
                                  href="/yachts-boats/azure-odyssey"
                                  className="w-inline-block"
                                  tabIndex={-1}
                                  aria-hidden="true"
                                >
                                  <h3
                                    id="w-node-_56863fcf-d9e4-ddc3-df1b-97bfb788b6c8-85f806fb"
                                    className="heading-hover"
                                    aria-hidden="true"
                                  >
                                   Aircraft Leasing
                                  </h3>
                                </a>
                                <div
                                  id="w-node-_56863fcf-d9e4-ddc3-df1b-97bfb788b6c5-85f806fb"
                                  className="text-content"
                                  aria-hidden="true"
                                >
                                  <p
                                    className="subtitle-category"
                                    aria-hidden="true"
                                  >
                                     Airlines rent planes for flexibility and cost savings, avoiding long-term ownership commitments.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="projects-mask w-slider-mask"
                  id="w-slider-mask-1"
                >
                  <div
                    className="project-slide w-slide"
                    aria-label="1 of 6"
                    role="group"
                    aria-hidden="true"
                  >
                    <div className="w-dyn-list" aria-hidden="true">
                      <div
                        role="list"
                        className="w-dyn-items"
                        aria-hidden="true"
                      >
                        <div
                          role="listitem"
                          className="w-dyn-item"
                          aria-hidden="true"
                        >
                          <div className="yacht-content" aria-hidden="true">
                            <a
                              href="#"
                              className="project-card-wrapper second w-inline-block"
                            >
                              <img
                                alt="yacht"
                                loading="lazy"
                                src={c}
                                sizes="(max-width: 479px) 85vw, (max-width: 767px) 90vw, (max-width: 991px) 80vw, (max-width: 1919px) 50vw, 900px"
                                srcSet={c}
                                className="image-yacht second"
                              />
                              <div className="background-radius" />
                            </a>

                            <div className="project-content" aria-hidden="true">
                              <div
                                id="w-node-_56863fcf-d9e4-ddc3-df1b-97bfb788b6c4-85f806fb"
                                className="project-card-bottom"
                                aria-hidden="true"
                              >
                                <a
                                  id="w-node-_23b1c14a-44ef-ad84-af10-d3a42fbad744-85f806fb"
                                  href="/yachts-boats/azure-odyssey"
                                  className="w-inline-block"
                                  tabIndex={-1}
                                  aria-hidden="true"
                                >
                                  <h3
                                    id="w-node-_56863fcf-d9e4-ddc3-df1b-97bfb788b6c8-85f806fb"
                                    className="heading-hover"
                                    aria-hidden="true"
                                  >
                                   Joy Ride
                                  </h3>
                                </a>
                                <div
                                  id="w-node-_56863fcf-d9e4-ddc3-df1b-97bfb788b6c5-85f806fb"
                                  className="text-content"
                                  aria-hidden="true"
                                >
                                  <p
                                    className="subtitle-category"
                                    aria-hidden="true"
                                  >
                                   Soar, thrill, vistas unfold. Wind whispers, blades dance. Skybound ecstasy, memories etched.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="projects-mask w-slider-mask"
                  id="w-slider-mask-1"
                >
                  <div
                    className="project-slide w-slide"
                    aria-label="1 of 6"
                    role="group"
                    aria-hidden="true"
                  >
                    <div className="w-dyn-list" aria-hidden="true">
                      <div
                        role="list"
                        className="w-dyn-items"
                        aria-hidden="true"
                      >
                        <div
                          role="listitem"
                          className="w-dyn-item"
                          aria-hidden="true"
                        >
                          <div className="yacht-content" aria-hidden="true">
                            <a
                              href="#"
                              className="project-card-wrapper second w-inline-block"
                            >
                              <img
                                alt="yacht"
                                loading="lazy"
                                src={ab}
                                sizes="(max-width: 479px) 85vw, (max-width: 767px) 90vw, (max-width: 991px) 80vw, (max-width: 1919px) 50vw, 900px"
                                srcSet={ab}
                                className="image-yacht second"
                              />
                              <div className="background-radius" />
                            </a>

                            <div className="project-content" aria-hidden="true">
                              <div
                                id="w-node-_56863fcf-d9e4-ddc3-df1b-97bfb788b6c4-85f806fb"
                                className="project-card-bottom"
                                aria-hidden="true"
                              >
                                <a
                                  id="w-node-_23b1c14a-44ef-ad84-af10-d3a42fbad744-85f806fb"
                                  href="/yachts-boats/azure-odyssey"
                                  className="w-inline-block"
                                  tabIndex={-1}
                                  aria-hidden="true"
                                >
                                  <h3
                                    id="w-node-_56863fcf-d9e4-ddc3-df1b-97bfb788b6c8-85f806fb"
                                    className="heading-hover"
                                    aria-hidden="true"
                                  >
                                   Aerial Advertising
                                  </h3>
                                </a>
                                <div
                                  id="w-node-_56863fcf-d9e4-ddc3-df1b-97bfb788b6c5-85f806fb"
                                  className="text-content"
                                  aria-hidden="true"
                                >
                                  <p
                                    className="subtitle-category"
                                    aria-hidden="true"
                                  >
                                     Elevate your brand with sky-high impact. Aerial Advertising, reaching new heights for your message
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
             
            </Swiper>

            {/* <div
              className="slide-arrow w-slider-arrow-left"
              role="button"
              tabIndex={0}
              aria-controls="w-slider-mask-1"
              aria-label="previous slide"
            >
              <div className="arrow-slide"></div>
            </div>
            <div
              className="slide-arrow right w-slider-arrow-right"
              role="button"
              tabIndex={0}
              aria-controls="w-slider-mask-1"
              aria-label="next slide"
            >
              <div className="arrow-slide"></div>
            </div> */}
            <div className="slide-nav-none w-slider-nav w-round">
              <div
                className="w-slider-dot"
                data-wf-ignore=""
                aria-label="Show slide 1 of 6"
                aria-pressed="false"
                role="button"
                tabIndex={-1}
                style={{ marginLeft: 3, marginRight: 3 }}
              />
              <div
                className="w-slider-dot"
                data-wf-ignore=""
                aria-label="Show slide 2 of 6"
                aria-pressed="false"
                role="button"
                tabIndex={-1}
                style={{ marginLeft: 3, marginRight: 3 }}
              />
              <div
                className="w-slider-dot"
                data-wf-ignore=""
                aria-label="Show slide 3 of 6"
                aria-pressed="false"
                role="button"
                tabIndex={-1}
                style={{ marginLeft: 3, marginRight: 3 }}
              />
              <div
                className="w-slider-dot"
                data-wf-ignore=""
                aria-label="Show slide 4 of 6"
                aria-pressed="false"
                role="button"
                tabIndex={-1}
                style={{ marginLeft: 3, marginRight: 3 }}
              />
              <div
                className="w-slider-dot w-active"
                data-wf-ignore=""
                aria-label="Show slide 5 of 6"
                aria-pressed="true"
                role="button"
                tabIndex={0}
                style={{ marginLeft: 3, marginRight: 3 }}
              />
              <div
                className="w-slider-dot"
                data-wf-ignore=""
                aria-label="Show slide 6 of 6"
                aria-pressed="false"
                role="button"
                tabIndex={-1}
                style={{ marginLeft: 3, marginRight: 3 }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Planesecton;
