import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import a from "../Assets/Services/p1.jpeg";
import b from "../Assets/Services/p2.jpeg";
import c from "../Assets/Services/p3.jpeg";
import d from "../Assets/Services/p4.jpeg";
import e from "../Assets/Services/p5.jpeg";
import f from "../Assets/Services/p6.jpeg";
import g from "../Assets/Services/p7.jpeg";
import h from "../Assets/Services/p8.jpeg";
import i from "../Assets/Services/p9.jpeg";
import j from "../Assets/Services/p10.jpeg";
import k from "../Assets/Services/p11.jpeg";
import l from "../Assets/Services/p12.jpeg";
import m from "../Assets/Services/p13.jpeg";
import i1 from "../Assets/Services/i1.png";
import i2 from "../Assets/Services/i2.png";
import i3 from "../Assets/Services/i3.png";
import i4 from "../Assets/Services/i4.png";
import abb1 from "../Assets/Services/abb1.png"
import abb2 from "../Assets/Services/abb2.jpg"
import abb3 from "../Assets/Services/abb3.jpg"
import p3 from "../Assets/Services/p3.jpeg"
import p1 from "../Assets/Services/p1.jpeg"
import p2 from "../Assets/Services/p2.jpeg"
import p4 from "../Assets/Services/p4.jpeg"
import p5 from "../Assets/Services/p5.jpeg"
import p6 from "../Assets/Services/p6.jpeg"
import ab1 from "../Assets/Services/ab1.webp"
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-pager.css";
import "lightgallery/css/lg-autoplay.css";
import lightGallery from "lightgallery";
import hali from "../Assets/hali.jpeg"
import h1 from "../Assets/Services/gallery/h1.png"
import h2 from "../Assets/Services/gallery/h2.jpeg"
import h3 from "../Assets/Services/gallery/h3.jpeg"
import h4 from "../Assets/Services/gallery/h4.jpeg"
import h5 from "../Assets/Services/gallery/h5.jpeg"
import h6 from "../Assets/Services/gallery/h6.jpeg"
const Aboutus = () => {
  useEffect(() => {
    // Initialize lightGallery
    lightGallery(document.getElementById("image-gallery"), {
      mode: "lg-fade",
      thumbnail: true,
      zoom: false,
      autoplay: false,
    });
  }, []);
  return (
    <div>
      <section className="section inner-banner">
        <div
          className="full-image"
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1.00421, 1.00421, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
            willChange: "transform",
          }}
        >
          <div className="banner-background-project" />
          <img
            srcSet={p3}
            loading="lazy"
            sizes="100vw"
            alt="yacht"
            src={p3}
            className="image-inner"
          />
        </div>
        <div className="flex-vertical">
          <div className="block-banner-top">
            <div className="left-description">
              <div className="bottom-block">
                <h1
                  data-w-id="634f48b0-e11c-2245-9719-d8033e826a99"
                  style={{
                    opacity: 1,
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                  className="text-color-white text-left"
                >
                  We make every trip unforgettable
                </h1>
                <div
                  data-w-id="634f48b0-e11c-2245-9719-d8033e826a9b"
                  style={{
                    opacity: 1,
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                  className="description-banner"
                >
                  {/* <p className="paragraph-large text-white text-left">
              At Rent Yacht and Boat, we pride ourselves on delivering
              exceptional service tailored to exceed your expectations.
            </p> */}
                </div>
                {/* <div
            data-w-id="634f48b0-e11c-2245-9719-d8033e826a9e"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
            className="banner-button-wrapper"
          >
            <a href="/contact-us" className="primary-button-yellow w-button">
              Book Now
            </a>
            <a href="/yachts" className="secondary-button-white w-button">
              discover more
            </a>
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section overflow-hidden">
        <div className="base-container w-container">
          <div className="about-5-info-wrapper">
            <div className="about-info-wrap-left">
              <div
                data-w-id="e3ec6ee1-ba7b-fd25-1f30-c94c79637f7f"
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
              >
                <h6 className="section-subtitle text-left">about company</h6>
                <h2 className="text-left">
                Welcome to Aerofoil Aviation
                </h2>
                <p className="paragraph-large text-left">
                  Run and managed by a team of experienced, highly qualified
                  individuals, the management team has established strong and
                  effective systems and procedures over the years that
                  streamline the process of charter business services.
                  aerofoil aviation has in-house air operations, engineering &
                  maintenance, own ground handling staff, highly trained pilots,
                  and a team of highly motivated and knowledgeable professionals
                  in support services.
                </p>
              </div>
              <div
                data-w-id="e3ec6ee1-ba7b-fd25-1f30-c94c79637f86"
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="about-info-cards-wrapper"
              >
                <div className="about-info-cards-wrap">
                  <div className="number">1K+</div>
                  <p className="paragraph-large">
                    With Years Of Aeronautical Expertise{" "}
                  </p>
                </div>
                <div className="about-info-cards-wrap">
                  <div className="number">83</div>
                  <p className="paragraph-large">
                  Our Adept Aerial Aviator
                  </p>
                </div>
              </div>
            </div>
            <div
              data-w-id="e3ec6ee1-ba7b-fd25-1f30-c94c79637f91"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
              className="about-info-wrap-right"
            >
              <img
                src={hali}
                loading="lazy"
                sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 97vw, (max-width: 1279px) 45vw, 545px"
                srcSet={hali}
                alt="team"
                className="capabilities-image first"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        data-w-id="79523893-612f-1ce7-01ae-78fa6d1a9686"
        className="section dark-background-overflow"
      >
        <div className="base-container-slider w-container">
        <div className="container">
          <div id="image-gallery" className="row">
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              href={h1}
              data-lg-size="1600-2400"
              data-lg-srcset={h1}
            >
              <img
                src={h1}
                style={{ height: "300px", width: "100%" }}
                alt="Enquery"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={h2}
              data-lg-size="1600-2400"
              data-lg-srcset={h2}
            >
              <img
                src={h2}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 1"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={h3}
              data-lg-size="1600-2400"
              data-lg-srcset={h3}
            >
              <img
                src={h3}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={h4}
              data-lg-size="1600-2400"
              data-lg-srcset={h4}
            >
              <img
                src={h4}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={h5}
              data-lg-size="1600-2400"
              data-lg-srcset={h5}
            >
              <img
                src={h5}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={h6}
              data-lg-size="1600-2400"
              data-lg-srcset={h6}
            >
              <img
                src={h6}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
          </div>
        </div>
        </div>
      </div>
      <section className="section">
        <div className="w-layout-blockcontainer base-container w-container">
          <div className="benefits-wrapper margin-bottom-xlarge">
            <div className="About-WhyUs">
              <div className="row">
                <div className="col-md-6 order-lg-1 order-2">
                  <div
                    id="w-node-_17fbd02a-0174-c33c-99d4-ad5b76db856b-85f806e1"
                    className="intro-content"
                  >
                    <div className="intro-content-wrap">
                      <h6
                        data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db856d"
                        style={{
                          opacity: 1,
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          transformStyle: "preserve-3d",
                        }}
                        className="section-subtitle text-left"
                      >
                        WHY CHOOSE Aerifoil Aviation
                      </h6>
                      <h2
                        data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db856f"
                        style={{
                          opacity: 1,

                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          transformStyle: "preserve-3d",
                        }}
                        className="margin-bottom-xsmall text-left"
                      >
                        Commitment to Quality
                      </h2>
                      <p
                        data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db8571"
                        style={{
                          opacity: 1,
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          transformStyle: "preserve-3d",
                        }}
                        className="paragraph-large text-left"
                      >
                        Committed to delivering superior facilities and
                        continuous improvement, our team strives for excellence
                        in all areas. All employees are expected to actively
                        participate in the quality improvement process. Our
                        dedicated customer service ensures exclusive charter
                        services with flexibility, reliability, and discretion.
                        operating 24/7 in district, we guarantee
                        safe flights with unparalleled speed, comfort, and
                        luxury, regardless of the weather.
                      </p>
                      <div
                        data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db8573"
                        style={{
                          opacity: 1,
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          transformStyle: "preserve-3d",
                          backgroundPosition:"5% 100%"
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 order-lg-2 order-1">
                  <div
                    id="w-node-_17fbd02a-0174-c33c-99d4-ad5b76db8576-85f806e1"
                    data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db8576"
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="intro-right-image"
                  >
                    <div className="hero-background-image benefit-background" style={{
                      backgroundImage: `url(${abb1})`,
                      backgroundPosition:"20% 100%"
                    }} />
                  </div>
                </div>
              </div>
            </div>

            <div className="About-WhyUs">
              <div className="row">
                <div className="col-md-6 order-2">
                  <div
                    id="w-node-_17fbd02a-0174-c33c-99d4-ad5b76db856b-85f806e1"
                    className="intro-content"
                  >
                    <div className="intro-content-wrap">
                      <h6
                        data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db856d"
                        style={{
                          opacity: 1,
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          transformStyle: "preserve-3d",
                        }}
                        className="section-subtitle text-left"
                      >
                        WHY CHOOSE Aerifoil Aviation
                      </h6>
                      <h2
                        data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db856f"
                        style={{
                          opacity: 1,

                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          transformStyle: "preserve-3d",
                        }}
                        className="margin-bottom-xsmall text-left"
                      >
                        Reputation for Reliability
                      </h2>
                      <p
                        data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db8571"
                        style={{
                          opacity: 1,
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          transformStyle: "preserve-3d",
                        }}
                        className="paragraph-large text-left"
                      >
                        Aerofoil Aviation boasts a diverse fleet and local pilot
                        base, ensuring a high dispatch rate and unmatched
                        reliability. with a rich history in the charter
                        industry, we consistently deliver flexible, efficient,
                        safe, and discreet solutions globally. Our repeat
                        clients trust us for diverse travel needs, thanks to our
                        competitive rates, top-notch customer service, and
                        uncompromising safety standards. Experience excellent
                        value for money with our efficient and reliable
                        aircraft, providing direct, on-demand services for swift
                        and secure travel to your destination.
                      </p>
                      <div
                        data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db8573"
                        style={{
                          opacity: 1,
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          transformStyle: "preserve-3d",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 order-1">
                  <div
                    id="w-node-_17fbd02a-0174-c33c-99d4-ad5b76db8576-85f806e1"
                    data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db8576"
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="intro-right-image"
                  >
                    <div className="hero-background-image benefit-background"  
                    style={{
                      backgroundImage: `url(${abb2})`,
                      backgroundPosition:"23% 100%"
                    }} />
                  </div>
                </div>
              </div>
            </div>

            <div className="About-WhyUs">
              <div className="row">
                <div className="col-md-6 order-lg-1 order-2">
                  <div
                    id="w-node-_17fbd02a-0174-c33c-99d4-ad5b76db856b-85f806e1"
                    className="intro-content"
                  >
                    <div className="intro-content-wrap">
                      <h6
                        data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db856d"
                        style={{
                          opacity: 1,
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          transformStyle: "preserve-3d",
                        }}
                        className="section-subtitle text-left"
                      >
                        WHY CHOOSE Aerifoil Aviation
                      </h6>
                      <h2
                        data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db856f"
                        style={{
                          opacity: 1,

                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          transformStyle: "preserve-3d",
                        }}
                        className="margin-bottom-xsmall text-left"
                      >
                        Focus on Safety
                      </h2>
                      <p
                        data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db8571"
                        style={{
                          opacity: 1,
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          transformStyle: "preserve-3d",
                        }}
                        className="paragraph-large text-left"
                      >
                        Our highly qualified pilots have a wealth of experience
                        and are regularly assessed by our Chief Pilot and
                        external testing officers. Our focus on safety and
                        continual improvement means our clients can relax,
                        knowing their flight is in safe and capable hands. We
                        continually strive to maintain high standards in
                        equipment, safety and security, in accordance with the
                        Aerofoil Aviation Safety Authority regulations, aircraft
                        manufacturer guidance and industry best practice.
                      </p>
                      <div
                        data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db8573"
                        style={{
                          opacity: 1,
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          transformStyle: "preserve-3d",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 order-lg-2 order-1">
                  <div
                    id="w-node-_17fbd02a-0174-c33c-99d4-ad5b76db8576-85f806e1"
                    data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db8576"
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="intro-right-image"
                  >
                    <div className="hero-background-image benefit-background" style={{
                      backgroundImage: `url(${abb3})`,
                      backgroundPosition:"73% 100%"
                    }}  />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-layout-grid our-services-grid">
            <div
              data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db8579"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
              className="service-card"
            >
              <div className="service-image-cont">
                <img
                  src={i1}
                  loading="lazy"
                  alt="service icon"
                  className="servise-icon"
                />
              </div>
              <h5
                className="text-primary-color"
                style={{ textAlign: "center" }}
              >
                Personalized Packages
              </h5>
              <p className="service-card-paragraph">
                Custom charter packages for private jets and helicopters,
                tailored to your desires.
                <br />
              </p>
            </div>
            <div
              data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db8581"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
              className="service-card"
            >
              <div className="service-image-cont">
                <img
                  src={i2}
                  loading="lazy"
                  alt="service icon"
                  className="servise-icon"
                />
              </div>
              <h5
                className="text-primary-color"
                style={{ textAlign: "center" }}
              >
                Event Planning Assistance
              </h5>
              <p className="service-card-paragraph">
                Planning an elite airborne event? Our team ensures a perfect
                celebration on private jets or helicopters.
                <br />
              </p>
            </div>
            <div
              data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db8589"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
              className="service-card"
            >
              <div className="service-image-cont">
                <img
                  src={i3}
                  loading="lazy"
                  alt="service icon"
                  className="servise-icon"
                />
              </div>
              <h5
                className="text-primary-color"
                style={{ textAlign: "center" }}
              >
                Flexible Booking Options
              </h5>
              <p className="service-card-paragraph">
                Our user-friendly platform streamlines booking and customization
                for private jets and helicopters.
                <br />
              </p>
            </div>
            <div
              data-w-id="17fbd02a-0174-c33c-99d4-ad5b76db8591"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
              className="service-card"
            >
              <div className="service-image-cont">
                <img
                  src={i4}
                  loading="lazy"
                  alt="service icon"
                  className="servise-icon"
                />
              </div>
              <h5
                className="text-primary-color"
                style={{ textAlign: "center" }}
              >
                Unforgettable Experiences
              </h5>
              <p className="service-card-paragraph">
                Whether you're yearning for serenity, excitement, or a fusion of
                both, explore our private jet and helicopter charters.
                <br />
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Aboutus;
