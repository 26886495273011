import React from 'react'
import Banner1 from "../../Assets/Services/banner.mp4"
const Banner = () => {
  return (
    <div>
      <section className="section home-banner">
  <div
    data-w-id="6aacaa1e-19c7-0dc0-48d2-83b538597b9a"
    className="full-image"
    style={{
      transform:
        "translate3d(0px, 0px, 0px) scale3d(1.05624, 1.05624, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
      transformStyle: "preserve-3d",
      willChange: "transform"
    }}
  >
    <div className="banner-background-project" />
    <video  autoPlay loop muted playsInline className="back-video vediodesine">
          <source src={Banner1} type="video/mp4" />
        </video>
  </div>
  <div className="flex-vertical">
    <div className="block-banner-top">
      <div className="left-description">
        <div className="bottom-block">
          <h1
            data-w-id="54cfbc23-4cdd-087b-6b23-342acddb772a"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
            className="text-color-white text-left"
          >
           
           We Keep The Places At Your  &amp; Finger Tips
          </h1>
          <div
            data-w-id="31b07b46-236d-77c2-5d7f-a6441133abd2"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
            className="description-banner"
          >
            <p className="paragraph-large text-white text-left" >
            Don’t just fly, experience the ultimate flying based on a foundation
of Excellent Service, Exclusivity &amp; Security.
            </p>
          </div>
          <div
            data-w-id="b8164480-2ab0-b44e-7ced-4ccb89d889a4"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
            className="banner-button-wrapper"
          >
            <a href="/Contectus" className="primary-button-yellow w-button">
              Book Now
            </a>
            <a href="/aboutus" className="secondary-button-white w-button">
              discover more
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="banner-slider-wrapper">
      <div
        data-w-id="90ffcc38-160c-37fd-ca20-5d716b1a8cc5"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="slider-bottom"
      >
        <div
          data-delay={4000}
          data-animation="slide"
          className="slider-projects w-slider"
          data-autoplay="false"
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="true"
          data-autoplay-limit={0}
          data-nav-spacing={3}
          data-duration={500}
          data-infinite="true"
          role="region"
          aria-label="carousel"
        >
         
          <div className="hidden w-slider-nav w-round">
            <div
              className="w-slider-dot w-active"
              data-wf-ignore=""
              aria-label="Show slide 1 of 6"
              aria-pressed="true"
              role="button"
              tabIndex={0}
              style={{ marginLeft: 3, marginRight: 3 }}
            />
            <div
              className="w-slider-dot"
              data-wf-ignore=""
              aria-label="Show slide 2 of 6"
              aria-pressed="false"
              role="button"
              tabIndex={-1}
              style={{ marginLeft: 3, marginRight: 3 }}
            />
            <div
              className="w-slider-dot"
              data-wf-ignore=""
              aria-label="Show slide 3 of 6"
              aria-pressed="false"
              role="button"
              tabIndex={-1}
              style={{ marginLeft: 3, marginRight: 3 }}
            />
            <div
              className="w-slider-dot"
              data-wf-ignore=""
              aria-label="Show slide 4 of 6"
              aria-pressed="false"
              role="button"
              tabIndex={-1}
              style={{ marginLeft: 3, marginRight: 3 }}
            />
            <div
              className="w-slider-dot"
              data-wf-ignore=""
              aria-label="Show slide 5 of 6"
              aria-pressed="false"
              role="button"
              tabIndex={-1}
              style={{ marginLeft: 3, marginRight: 3 }}
            />
            <div
              className="w-slider-dot"
              data-wf-ignore=""
              aria-label="Show slide 6 of 6"
              aria-pressed="false"
              role="button"
              tabIndex={-1}
              style={{ marginLeft: 3, marginRight: 3 }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default Banner
