
import './App.css';
import Contectus from './pages/Contectus';
import Footer from "./components/Footer"
import Hadder from './components/Hadder';
import "./css/style.css"
import Aboutus from './pages/Aboutus';
import Home from './pages/Home';
import Service from './pages/Service';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Servicenext from './pages/Service-next';

function App() {
  return (
    <div className="App">
        <BrowserRouter> 
      <Hadder/>
      <Routes>

     <Route path='/' element={<Home/>}></Route>
     <Route path='/aboutus' element={<Aboutus/>}></Route>
     <Route path='/Contectus' element={<Contectus/>}></Route>
     <Route path='/Servicees' element={<Servicenext/>}></Route>
     <Route path='/Service' element={<Service/>}></Route>
      </Routes>
<Footer/>
    </BrowserRouter>
    </div>
  );
}

export default App;
