import React from 'react'
import ab1 from "../../Assets/Services/ab1.webp"
import hali from "../../Assets/hali.jpeg"
const Aboutsection = () => {
  return (
    <div>
      <section className="section">
  <div className="w-layout-blockcontainer base-container w-container">
    <div className="w-layout-grid grid-about">
      <div
        id="w-node-fc9019ac-fe66-ec30-0e13-e9fc8c23c669-85f806fb"
        data-w-id="fc9019ac-fe66-ec30-0e13-e9fc8c23c669"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="block-image"
      >
        <img
          src={hali}
          loading="lazy"
          width={480}
          height={600}
          alt="yacht"
          srcSet={hali}
          sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1279px) 40vw, 475.7109375px"
          className="image-grid"
        />
      </div>
      <div
        data-w-id="014fa66d-c321-478c-2d0f-6ec917626821"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="gid-column-right"
      >
        <div className="top-gid">
          <h6 className="section-subtitle text-left">OUR STORY</h6>
          <h2 className='text-left'>
          Book Your Private Jet or Helicopter for an Unforgettable Experience.</h2>
          <p className="paragraph-large text-left">
          Whether you&#39;re travelling with colleagues or with family, we take our exceptional service to the highest
level to fly you personally. Fly up to the utmost comfort of our customised aircraft with the flexibility of
private jet &amp; helicopter. Reach destinations with flight from your chosen departure airport or helipad. A
luxury interior and a service tailored to you will make your flights the ultimate in exclusive travel.
          </p>
          <div style={{display:"flex"}}>

          
          <a
            href="/about-us"
            className="secondary-button margin-top-medium w-button text-left"
          >
            About Us
          </a>
          </div>
        </div>
        <div className="block-image margin-top-large">
          <img
            src="https://www.suu.edu/blog/images/aviation/2020-10-difference-between-airplane-and-helicopter-6.jpg"
            loading="lazy"
            sizes="(max-width: 479px) 91vw, (max-width: 767px) 94vw, (max-width: 1279px) 53vw, 634.2734375px"
            srcSet="https://www.suu.edu/blog/images/aviation/2020-10-difference-between-airplane-and-helicopter-6.jpg"
            alt="boats"
            className="image-grid"
          />
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default Aboutsection
