import React from 'react'
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ab2 from "../../Assets/Services/ab2.jpg"
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
const Testimonial = () => {
  return (
    <div>
      <section className="section bg-image">
  <div className="base-container position-relative w-container">
    <div
      data-w-id="cd73b0a8-a598-3d24-3ebf-e50a727fb923"
      style={{
        opacity: 1,
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
      className="testimonials-slider-holder"
    >
      <div
        data-delay={4000}
        data-animation="slide"
        className="testimonials-slider-style w-slider"
        data-autoplay="false"
        data-easing="ease"
        data-hide-arrows="false"
        data-disable-swipe="false"
        data-autoplay-limit={0}
        data-nav-spacing={3}
        data-duration={500}
        data-infinite="false"
        role="region"
        aria-label="carousel"
      >
        <div
          className="testimonials-slider-mask-style w-slider-mask"
          id="w-slider-mask-3"
        >
           <Swiper
              // navigation={{
              //   prevEl: ".slide-arrow-left",
              //   nextEl: ".slide-arrow-right",
              // }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[ Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
          <div
            className="slide-testimonials w-slide"
            aria-label="1 of 3"
            role="group"
            aria-hidden="true"
           
          >
            <div className="block-testimonials" aria-hidden="true">
              <div className="testimonials-text" aria-hidden="true">
                "
                Our experience with Aerofoil Aviation was nothing short of exceptional! The ease of booking, the top-notch customer service, and the sheer luxury of the airplane exceeded our expectations. Flying with them is truly a VIP experience, and we can't wait for our next adventure in the skies!
              </div>
              <div className=' center-content'>
              <div className="display-flex-horizontal-gap" aria-hidden="true">
                <div aria-hidden="true">
                  <img
                    src="https://assets-global.website-files.com/651f1b0f35b75e7485f80693/65253edfc9f60eedf06dccdc_vicky-hladynets-C8Ta0gwPbQg-unsplash.webp"
                    loading="lazy"
                    sizes="(max-width: 479px) 27vw, 60px"
                    srcSet="https://assets-global.website-files.com/651f1b0f35b75e7485f80693/65253edfc9f60eedf06dccdc_vicky-hladynets-C8Ta0gwPbQg-unsplash-p-500.webp 500w, https://assets-global.website-files.com/651f1b0f35b75e7485f80693/65253edfc9f60eedf06dccdc_vicky-hladynets-C8Ta0gwPbQg-unsplash.webp 640w"
                    alt="client"
                    className="image-testimonials"
                    aria-hidden="true"
                  />
                </div>
                <div aria-hidden="true">
                  <h6
                    className="margin-bottom-xsmall text-color-white"
                    aria-hidden="true"
                  >
                    Rahul
                  </h6>
               
                </div>
              </div>
              </div>
             
            </div>
          </div>
          </SwiperSlide>
          <SwiperSlide>
          <div
            className="slide-testimonials w-slide"
            aria-label="1 of 3"
            role="group"
            aria-hidden="true"
           
          >
            <div className="block-testimonials" aria-hidden="true">
              <div className="testimonials-text" aria-hidden="true">
                "
                Aerofoil Aviation turned our special occasion into an unforgettable memory. The helicopter we booked was not only stylish but also provided breathtaking views. The attentive crew and seamless booking process made our journey truly remarkable. Highly recommended for anyone looking for a unique and upscale flying experience."
              </div>
              <div className=' center-content'>
              <div className="display-flex-horizontal-gap" aria-hidden="true">
                <div aria-hidden="true">
                  <img
                    src="https://photoheads.co.uk/wp-content/uploads/2020/05/headshot-with-client-testimonial.jpg"
                    loading="lazy"
                    sizes="(max-width: 479px) 27vw, 60px"
                    srcSet="https://photoheads.co.uk/wp-content/uploads/2020/05/headshot-with-client-testimonial.jpg 500w, https://photoheads.co.uk/wp-content/uploads/2020/05/headshot-with-client-testimonial.jpg 640w"
                    alt="client"
                    className="image-testimonials"
                    aria-hidden="true"
                  />
                </div>
                <div aria-hidden="true">
                  <h6
                    className="margin-bottom-xsmall text-color-white"
                    aria-hidden="true"
                  >
                    Raman
                  </h6>
                
                </div>
              </div>
              </div>
             
            </div>
          </div>
          </SwiperSlide>
          <SwiperSlide>
          <div
            className="slide-testimonials w-slide"
            aria-label="1 of 3"
            role="group"
            aria-hidden="true"
           
          >
            <div className="block-testimonials" aria-hidden="true">
              <div className="testimonials-text" aria-hidden="true">
                "
                Booking a private jet with Aerofoil Aviation was a game-changer for our business travel. The efficiency in booking, the comfortable interiors, and the punctuality of their service made our trips stress-free and enjoyable. Aerofoil Aviation has become our go-to choice for corporate travel, and we couldn't be more satisfied."
              </div>
              <div className=' center-content'>
              <div className="display-flex-horizontal-gap" aria-hidden="true">
                <div aria-hidden="true">
                  <img
                    src="https://t3.ftcdn.net/jpg/03/53/59/46/360_F_353594684_Ca3p9RIc3xgQ1Y6ff7Jk6nVe54v9NbpQ.jpg"
                    loading="lazy"
                    sizes="(max-width: 479px) 27vw, 60px"
                    srcSet="https://t3.ftcdn.net/jpg/03/53/59/46/360_F_353594684_Ca3p9RIc3xgQ1Y6ff7Jk6nVe54v9NbpQ.jpg 500w, https://t3.ftcdn.net/jpg/03/53/59/46/360_F_353594684_Ca3p9RIc3xgQ1Y6ff7Jk6nVe54v9NbpQ.jpg 640w"
                    alt="client"
                    className="image-testimonials"
                    aria-hidden="true"
                  />
                </div>
                <div aria-hidden="true">
                  <h6
                    className="margin-bottom-xsmall text-color-white"
                    aria-hidden="true"
                  >
                    Rohan
                  </h6>
                 
                </div>
              </div>
              </div>
             
            </div>
          </div>
          </SwiperSlide>
         
         
          </Swiper>
        </div>
      
        <div className="hidden w-slider-nav w-round">
          <div
            className="w-slider-dot"
            data-wf-ignore=""
            aria-label="Show slide 1 of 3"
            aria-pressed="false"
            role="button"
            tabIndex={-1}
            style={{ marginLeft: 3, marginRight: 3 }}
          />
          <div
            className="w-slider-dot"
            data-wf-ignore=""
            aria-label="Show slide 2 of 3"
            aria-pressed="false"
            role="button"
            tabIndex={-1}
            style={{ marginLeft: 3, marginRight: 3 }}
          />
          <div
            className="w-slider-dot w-active"
            data-wf-ignore=""
            aria-label="Show slide 3 of 3"
            aria-pressed="true"
            role="button"
            tabIndex={0}
            style={{ marginLeft: 3, marginRight: 3 }}
          />
        </div>
      </div>
    </div>
  </div>

  <div className="background-project" />
</section>





    </div>
  )
}

export default Testimonial
