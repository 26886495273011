import React from 'react'
import add from "../Assets/Services/add.jpg"
import Ambulance from "../Assets/Services/ambulance.png"
import asss from "../Assets/Services/asss.jpg"
const Servicenext = () => {
  return (
    <div>
       <section className="section inner-banner">
  <div
    className="full-image"
    style={{
      transform:
        "translate3d(0px, 0px, 0px) scale3d(1.00207, 1.00207, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
      transformStyle: "preserve-3d",
      willChange: "transform"
    }}
  >
    <div className="banner-background-project" />
    <img
      srcSet="https://images.prestigeonline.com/wp-content/uploads/sites/3/2023/05/25131004/d_g700_a_mkt_00041_v02_webprod-1024x846-1.jpeg"
      loading="lazy"
      sizes="100vw"
      alt="yacht"
      src="https://images.prestigeonline.com/wp-content/uploads/sites/3/2023/05/25131004/d_g700_a_mkt_00041_v02_webprod-1024x846-1.jpeg"
      className="image-inner"
    />
  </div>
  <div className="flex-vertical">
    <div className="block-banner-top">
      <div className="left-description inner-page">
        <div className="bottom-block">
          <h1
            data-w-id="7ac3fbaa-f7d1-b16e-ddf5-b8d9204772d4"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
            className="text-color-white text-left"
          >
            Your airborne odyssey beckons on the distant horizon
          </h1>
          <div
            data-w-id="7ac3fbaa-f7d1-b16e-ddf5-b8d9204772d6"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
            className="description-banner"
          >
            {/* <p className="paragraph-large text-white text-left">
              At Rent Yacht &amp; Boat, we invite you to indulge in a luxurious
              experience on the water, combining the thrill of sailing with the
              sophistication of an exquisite event.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
 
      <div className="section">
  <div className="base-container w-container">
    <div
      data-w-id="34930b44-50eb-26be-a832-a264ad933292"
      style={{
        opacity: 1,
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
      data-current="All"
      data-easing="ease-out"
      data-duration-in={100}
      data-duration-out={100}
      className="yachts-grid-tabs w-tabs"
    >
      <div className="tabs-menu-yachts w-tab-menu" role="tablist">
   
      </div>
      <div className="w-tab-content">
        <div
          data-w-tab="All"
          className="w-tab-pane"
          id="w-tabs-0-data-w-pane-0"
          role="tabpanel"
          aria-labelledby="w-tabs-0-data-w-tab-0"
          style={{}}
        >
          <div className="boats-collection w-dyn-list">
            <div role="list" className="boats-list w-dyn-items">
              <div role="listitem" className="collection-item-boat w-dyn-item">
                <div className="list-service-item">
                  <a
                    data-w-id="d911487a-ddfc-eada-0654-3fcf4254ecdd"
                    href="#"
                    className="boat-image-link w-inline-block"
                  >
                    <img
                      width={60}
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d"
                      }}
                      alt="Services"
                      src="https://assets-global.website-files.com/651f1b1035b75e7485f80716/65255addfaf4654a99b29a0e_pexels-fred-9287742%20copy.jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 46vw, (max-width: 1279px) 30vw, 369.71875px"
                      srcSet="https://assets-global.website-files.com/651f1b1035b75e7485f80716/65255addfaf4654a99b29a0e_pexels-fred-9287742%20copy-p-500.jpg 500w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/65255addfaf4654a99b29a0e_pexels-fred-9287742%20copy-p-800.jpg 800w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/65255addfaf4654a99b29a0e_pexels-fred-9287742%20copy-p-1080.jpg 1080w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/65255addfaf4654a99b29a0e_pexels-fred-9287742%20copy-p-1600.jpg 1600w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/65255addfaf4654a99b29a0e_pexels-fred-9287742%20copy.jpg 1920w"
                      className="services-image-cover"
                    />
                  </a>
                  <div className="boat-content-block">
                    <a
                      href="#"
                      className="w-inline-block"
                    >
                      <h4 className="link-hover">Sunset Serenade</h4>
                    </a>
                    <div className="boats-details">
                      <p>Length</p>
                      <h6>38 m</h6>
                    </div>
                    <div className="boats-details">
                      <p>Year</p>
                      <h6>2015</h6>
                    </div>
                    <div className="boats-details">
                      <p>Capacity</p>
                      <h6>8</h6>
                    </div>
                    <div className="boats-details">
                      <p>Price</p>
                      <h6>$3563</h6>
                    </div>
                    <a
                      href="#"
                      className="secondary-button margin-top-medium w-button"
                    >
                      See More
                    </a>
                  </div>
                </div>
              </div>
              <div role="listitem" className="collection-item-boat w-dyn-item">
                <div className="list-service-item">
                  <a
                    data-w-id="d911487a-ddfc-eada-0654-3fcf4254ecdd"
                    href="#"
                    className="boat-image-link w-inline-block"
                  >
                    <img
                      width={60}
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d"
                      }}
                      alt="Services"
                      src="https://assets-global.website-files.com/651f1b1035b75e7485f80716/652558dffee726e2a68a890e_marjan-blan-TRtjX1XUyUg-unsplash%20copy.jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 46vw, (max-width: 1279px) 30vw, 369.71875px"
                      srcSet="https://assets-global.website-files.com/651f1b1035b75e7485f80716/652558dffee726e2a68a890e_marjan-blan-TRtjX1XUyUg-unsplash%20copy-p-500.jpg 500w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/652558dffee726e2a68a890e_marjan-blan-TRtjX1XUyUg-unsplash%20copy-p-800.jpg 800w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/652558dffee726e2a68a890e_marjan-blan-TRtjX1XUyUg-unsplash%20copy.jpg 855w"
                      className="services-image-cover"
                    />
                  </a>
                  <div className="boat-content-block">
                    <a
                      href="#"
                      className="w-inline-block"
                    >
                      <h4 className="link-hover">Azure Odyssey</h4>
                    </a>
                    <div className="boats-details">
                      <p>Length</p>
                      <h6>42 m</h6>
                    </div>
                    <div className="boats-details">
                      <p>Year</p>
                      <h6>2016</h6>
                    </div>
                    <div className="boats-details">
                      <p>Capacity</p>
                      <h6>6</h6>
                    </div>
                    <div className="boats-details">
                      <p>Price</p>
                      <h6>$4238</h6>
                    </div>
                    <a
                      href="#"
                      className="secondary-button margin-top-medium w-button"
                    >
                      See More
                    </a>
                  </div>
                </div>
              </div>
              <div role="listitem" className="collection-item-boat w-dyn-item">
                <div className="list-service-item">
                  <a
                    data-w-id="d911487a-ddfc-eada-0654-3fcf4254ecdd"
                    href="#"
                    className="boat-image-link w-inline-block"
                  >
                    <img
                      width={60}
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d"
                      }}
                      alt="Services"
                      src="https://www.airchartersindia.net/images/home/private-charter.jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 46vw, (max-width: 1279px) 30vw, 369.71875px"
                      srcSet="https://www.airchartersindia.net/images/home/private-charter.jpg"
                      className="services-image-cover"
                    />
                  </a>
                  <div className="boat-content-block">
                    <a
                      href="#"
                      className="w-inline-block"
                    >
                      <h4 className="link-hover">Harbor Bliss</h4>
                    </a>
                    <div className="boats-details">
                      <p>Length</p>
                      <h6>43 m</h6>
                    </div>
                    <div className="boats-details">
                      <p>Year</p>
                      <h6>2018</h6>
                    </div>
                    <div className="boats-details">
                      <p>Capacity</p>
                      <h6>12</h6>
                    </div>
                    <div className="boats-details">
                      <p>Price</p>
                      <h6>$5963</h6>
                    </div>
                    <a
                      href="#"
                      className="secondary-button margin-top-medium w-button"
                    >
                      See More
                    </a>
                  </div>
                </div>
              </div>
              <div role="listitem" className="collection-item-boat w-dyn-item">
                <div className="list-service-item">
                  <a
                    data-w-id="d911487a-ddfc-eada-0654-3fcf4254ecdd"
                    href="#"
                    className="boat-image-link w-inline-block"
                  >
                    <img
                      width={60}
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d"
                      }}
                      alt="Services"
                      src="https://assets-global.website-files.com/651f1b1035b75e7485f80716/65255755809817b1ea5305e0_tobias-tullius-XZOO6QHub60-unsplash.jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 46vw, (max-width: 1279px) 30vw, 369.71875px"
                      srcSet="https://assets-global.website-files.com/651f1b1035b75e7485f80716/65255755809817b1ea5305e0_tobias-tullius-XZOO6QHub60-unsplash-p-500.jpg 500w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/65255755809817b1ea5305e0_tobias-tullius-XZOO6QHub60-unsplash-p-800.jpg 800w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/65255755809817b1ea5305e0_tobias-tullius-XZOO6QHub60-unsplash-p-1080.jpg 1080w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/65255755809817b1ea5305e0_tobias-tullius-XZOO6QHub60-unsplash.jpg 1488w"
                      className="services-image-cover"
                    />
                  </a>
                  <div className="boat-content-block">
                    <a
                      href="#"
                      className="w-inline-block"
                    >
                      <h4 className="link-hover">Blue Horizon</h4>
                    </a>
                    <div className="boats-details">
                      <p>Length</p>
                      <h6>36 m</h6>
                    </div>
                    <div className="boats-details">
                      <p>Year</p>
                      <h6>2019</h6>
                    </div>
                    <div className="boats-details">
                      <p>Capacity</p>
                      <h6>4</h6>
                    </div>
                    <div className="boats-details">
                      <p>Price</p>
                      <h6>$3698</h6>
                    </div>
                    <a
                      href="#"
                      className="secondary-button margin-top-medium w-button"
                    >
                      See More
                    </a>
                  </div>
                </div>
              </div>
              <div role="listitem" className="collection-item-boat w-dyn-item">
                <div className="list-service-item">
                  <a
                    data-w-id="d911487a-ddfc-eada-0654-3fcf4254ecdd"
                    href="#"
                    className="boat-image-link w-inline-block"
                  >
                    <img
                      width={60}
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d"
                      }}
                      alt="Services"
                      src="https://assets-global.website-files.com/651f1b1035b75e7485f80716/65251bb47b775fea747343a3_pexels-anthony-brown-14494631.jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 46vw, (max-width: 1279px) 30vw, 369.71875px"
                      srcSet="https://assets-global.website-files.com/651f1b1035b75e7485f80716/65251bb47b775fea747343a3_pexels-anthony-brown-14494631-p-500.jpg 500w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/65251bb47b775fea747343a3_pexels-anthony-brown-14494631-p-800.jpg 800w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/65251bb47b775fea747343a3_pexels-anthony-brown-14494631-p-1080.jpg 1080w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/65251bb47b775fea747343a3_pexels-anthony-brown-14494631-p-1600.jpg 1600w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/65251bb47b775fea747343a3_pexels-anthony-brown-14494631.jpg 1920w"
                      className="services-image-cover"
                    />
                  </a>
                  <div className="boat-content-block">
                    <a
                      href="#"
                      className="w-inline-block"
                    >
                      <h4 className="link-hover">Aqua Dream</h4>
                    </a>
                    <div className="boats-details">
                      <p>Length</p>
                      <h6>29 m</h6>
                    </div>
                    <div className="boats-details">
                      <p>Year</p>
                      <h6>2014</h6>
                    </div>
                    <div className="boats-details">
                      <p>Capacity</p>
                      <h6>6</h6>
                    </div>
                    <div className="boats-details">
                      <p>Price</p>
                      <h6>$2563</h6>
                    </div>
                    <a
                      href="/yachts-boats/aqua-dream"
                      className="secondary-button margin-top-medium w-button"
                    >
                      See More
                    </a>
                  </div>
                </div>
              </div>
              <div role="listitem" className="collection-item-boat w-dyn-item">
                <div className="list-service-item">
                  <a
                    data-w-id="d911487a-ddfc-eada-0654-3fcf4254ecdd"
                    href="#"
                    className="boat-image-link w-inline-block"
                  >
                    <img
                      width={60}
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d"
                      }}
                      alt="Services"
                      src="https://assets-global.website-files.com/651f1b1035b75e7485f80716/6523e9c9301b9c94cec39509_jean-cloete-gX_04X-_GbQ-unsplash%20(2).jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 46vw, (max-width: 1279px) 30vw, 369.71875px"
                      srcSet="https://assets-global.website-files.com/651f1b1035b75e7485f80716/6523e9c9301b9c94cec39509_jean-cloete-gX_04X-_GbQ-unsplash%20(2)-p-500.jpg 500w, https://assets-global.website-files.com/651f1b1035b75e7485f80716/6523e9c9301b9c94cec39509_jean-cloete-gX_04X-_GbQ-unsplash%20(2).jpg 640w"
                      className="services-image-cover"
                    />
                  </a>
                  <div className="boat-content-block">
                    <div className="row">
                      <div className="col-6">
                      <a
                      href="#"
                      className="w-inline-block"
                    >
                      <h4 className="link-hover">Ocean Serenity</h4>
                    </a>
                      </div>
                      <div className="col-6">
                      <a
                      href="#"
                      className="secondary-button margin-top-medium w-button"
                    >
                      See More
                    </a>
                      </div>
                    </div>
                  
                   
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              role="navigation"
              aria-label="List"
              className="w-pagination-wrapper"
            />
          </div>
        </div>
        <div
          data-w-tab="Yachts"
          className="w-tab-pane w--tab-active"
          id="w-tabs-0-data-w-pane-1"
          role="tabpanel"
          aria-labelledby="w-tabs-0-data-w-tab-1"
          style={{ opacity: 1, transition: "opacity 100ms ease-out 0s" }}
        >
          <div className="boats-collection w-dyn-list">
            <div role="list" className="boats-list w-dyn-items">
              <div role="listitem" className="collection-item-boat w-dyn-item">
                <div className="list-service-item">
                  <a
                    data-w-id="b19ed15a-f0a5-3077-d593-83c6031ca7e4"
                    href="#"
                    className="boat-image-link w-inline-block"
                  >
                    <img
                      width={60}
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d"
                      }}
                      alt="Services"
                      src="https://travelweekly.s3.eu-west-1.amazonaws.com/prod/original/9/0/7/0/8/aircharterservicenew-jpg.jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 46vw, (max-width: 1279px) 30vw, 369.71875px"
                      srcSet="https://travelweekly.s3.eu-west-1.amazonaws.com/prod/original/9/0/7/0/8/aircharterservicenew-jpg.jpg"
                      className="services-image-cover"
                    />
                  </a>
                  <div className="boat-content-block">
                    <a
                      href="#"
                      className="w-inline-block"
                    >
                      <h4 className="link-hover">Air Charter </h4>
                      <p style={{textAlign:"justify"}}>
Experience the epitome of luxury and convenience with our premier Air Charter services. Soar above the ordinary and enjoy personalized travel tailored to your needs. Our fleet of state-of-the-art aircraft ensures a seamless journey, offering you the freedom to fly on your schedule. Elevate your travel experience with us
                      </p>
                    </a>
                  {/* <div>

               
                    <a
                      href="#"
                      className="secondary-button margin-top-medium w-button"
                    >
                      See More
                    </a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div role="listitem" className="collection-item-boat w-dyn-item">
                <div className="list-service-item">
                  <a
                    data-w-id="b19ed15a-f0a5-3077-d593-83c6031ca7e4"
                    href="#"
                    className="boat-image-link w-inline-block"
                  >
                    <img
                      width={60}
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d"
                      }}
                      alt="Services"
                      src={Ambulance}
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 46vw, (max-width: 1279px) 30vw, 369.71875px"
                      srcSet={Ambulance}
                      className="services-image-cover"
                    />
                  </a>
                  <div className="boat-content-block">
                    <a
                      href="#"
                      className="w-inline-block"
                    >
                      <h4 className="link-hover">Air Ambulance </h4>
                      <p style={{textAlign:"justify"}}>
Air Ambulance services provide critical and swift medical transportation for individuals in emergency situations. Equipped with advanced life support systems, trained medical personnel, and rapid response capabilities, these aircraft ensure prompt and safe transfer of patients to medical facilities. Their efficiency plays a vital role in saving lives during critical moments.
                      </p>
                    </a>
                    {/* <div className="boats-details">
                      <p>Length</p>
                      <h6>36 m</h6>
                    </div> */}
                 
                    {/* <a
                      href="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi, vel nisi expedita error odit explicabo provident voluptatibus architecto dolorem numquam, nesciunt tenetur repellendus hic, dolore commodi ab recusandae velit. Modi!#"
                      className="secondary-button margin-top-medium w-button"
                    >
                      See More
                    </a> */}
                  </div>
                </div>
              </div>
              <div role="listitem" className="collection-item-boat w-dyn-item">
                <div className="list-service-item">
                  <a
                    data-w-id="b19ed15a-f0a5-3077-d593-83c6031ca7e4"
                    href="#"
                    className="boat-image-link w-inline-block"
                  >
                    <img
                      width={60}
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d"
                      }}
                      alt="Services"
                      src="https://5.imimg.com/data5/QH/TD/MY-1632730/private-aircraft-leasing-acmi.jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 46vw, (max-width: 1279px) 30vw, 369.71875px"
                      srcSet="https://5.imimg.com/data5/QH/TD/MY-1632730/private-aircraft-leasing-acmi.jpg"
                      className="services-image-cover"
                    />
                  </a>
                  <div className="boat-content-block">
                    <a
                      href="#"
                      className="w-inline-block"
                    >
                      <h4 className="link-hover">Aircraft Leasing</h4>
                      <p style={{textAlign:"justify"}}>Aircraft leasing offers businesses a strategic advantage by providing cost-effective access to a diverse fleet without the burden of ownership. Companies benefit from flexibility, avoiding hefty upfront costs, and can easily upgrade or adjust their aircraft according to evolving needs. This model proves particularly advantageous in the dynamic aviation industry.
                      </p>
                    </a>
                    {/* <div className="boats-details">
                      <p>Length</p>
                      <h6>36 m</h6>
                    </div> */}
                   
                    {/* <a
                      href="#"
                      className="secondary-button margin-top-medium w-button"
                    >
                      See More
                    </a> */}
                  </div>
                </div>
              </div>
              <div role="listitem" className="collection-item-boat w-dyn-item">
                <div className="list-service-item">
                  <a
                    data-w-id="b19ed15a-f0a5-3077-d593-83c6031ca7e4"
                    href="#"
                    className="boat-image-link w-inline-block"
                  >
                    <img
                      width={60}
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d"
                      }}
                      alt="Services"
                      src={asss}
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 46vw, (max-width: 1279px) 30vw, 369.71875px"
                      srcSet={asss}
                      className="services-image-cover"
                    />
                  </a>
                  <div className="boat-content-block">
                    <a
                      href="#"
                      className="w-inline-block"
                    >
                      <h4 className="link-hover">Aircraft Spares</h4>
                      <p style={{textAlign:"justify"}}>
Aircraft spares play a critical role in ensuring the continuous operation and safety of aviation equipment. These essential components, ranging from engines and avionics to landing gear and accessories, are meticulously crafted to meet stringent standards. Reliable sourcing of quality aircraft spares is imperative for airlines and maintenance teams to uphold operational excellence, minimize downtime, and prioritize passenger safety. The aviation industry relies on a robust supply chain for timely access to genuine and certified aircraft spares, fostering efficiency and compliance with regulatory requirements.
                      </p>
                    </a>
                  
                    {/* <a
                      href="#"
                      className="secondary-button margin-top-medium w-button"
                    >
                      See More
                    </a> */}
                  </div>
                </div>
              </div>
              <div role="listitem" className="collection-item-boat w-dyn-item">
                <div className="list-service-item">
                  <a
                    data-w-id="b19ed15a-f0a5-3077-d593-83c6031ca7e4"
                    href="#"
                    className="boat-image-link w-inline-block"
                  >
                    <img
                      width={60}
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                        objectFit:"inherit"
                      }}
                      alt="Services"
                      src={add}
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 46vw, (max-width: 1279px) 30vw, 369.71875px "
                      srcSet={add}
                      className="services-image-cover"
                    />
                  </a>
                  <div className="boat-content-block">
                    <a
                      href="#"
                      className="w-inline-block"
                    >
                      <h4 className="link-hover">Aircraft Advertising </h4>
                      <p style={{textAlign:"justify"}}>
Introducing the latest innovation in aviation excellence! Our cutting-edge aircraft seamlessly blend technology and comfort, setting new standards for air travel. Elevate your brand with our fleet of sleek, high-performance planes. Maximize exposure, captivate audiences, and soar above the competition. Your success takes flight with our unparalleled aircraft advertising solutions.
                      </p>
                    </a>
                    {/* <div className="boats-details">
                      <p>Length</p>
                      <h6>36 m</h6>
                    </div> */}
                 
                    {/* <a
                      href="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi, vel nisi expedita error odit explicabo provident voluptatibus architecto dolorem numquam, nesciunt tenetur repellendus hic, dolore commodi ab recusandae velit. Modi!#"
                      className="secondary-button margin-top-medium w-button"
                    >
                      See More
                    </a> */}
                  </div>
                </div>
              </div>
              <div role="listitem" className="collection-item-boat w-dyn-item">
                <div className="list-service-item">
                  <a
                    data-w-id="b19ed15a-f0a5-3077-d593-83c6031ca7e4"
                    href="#"
                    className="boat-image-link w-inline-block"
                  >
                    <img
                      width={60}
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d"
                      }}
                      alt="Services"
                      src="https://static-cse.canva.com/blob/562150/DronePhotography1.jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 46vw, (max-width: 1279px) 30vw, 369.71875px"
                      srcSet="https://static-cse.canva.com/blob/562150/DronePhotography1.jpg"
                      className="services-image-cover"
                    />
                  </a>
                  <div className="boat-content-block">
                    <a
                      href="#"
                      className="w-inline-block"
                    >
                      <h4 className="link-hover">Aerial Shooting</h4>
                      <p style={{textAlign:"justify"}}>
Aerial shooting, a captivating blend of technology and artistry, involves capturing breathtaking images or videos from elevated vantage points. Utilizing drones or aircraft, skilled photographers navigate the skies, delivering stunning perspectives of landscapes, events, and architecture. This dynamic form of visual storytelling continues to redefine the boundaries of creativity and innovation. 
                      </p>
                    </a>
                    {/* <div className="boats-details">
                      <p>Length</p>
                      <h6>36 m</h6>
                    </div> */}
                   
                    {/* <a
                      href="#"
                      className="secondary-button margin-top-medium w-button"
                    >
                      See More
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              role="navigation"
              aria-label="List"
              className="w-pagination-wrapper"
            />
          </div>
        </div>
     
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Servicenext
