import React from 'react'
import p1 from "../Assets/Services/p1.jpeg"
import p2 from "../Assets/Services/p2.jpeg"
import p3 from "../Assets/Services/p3.jpeg"
import p4 from "../Assets/Services/p4.jpeg"
import p5 from "../Assets/Services/p5.jpeg"
import p6 from "../Assets/Services/p6.jpeg"
import p7 from "../Assets/Services/p7.jpeg"
import p8 from "../Assets/Services/p8.jpeg"
import p9 from "../Assets/Services/p9.jpeg"
import p10 from "../Assets/Services/p10.jpeg"
import p11 from "../Assets/Services/p11.jpeg"
import p12 from "../Assets/Services/p12.jpeg"
import p13 from "../Assets/Services/p13.jpeg"
const Service = () => {
  return (
    <div>
      <section className="section inner-banner">
  <div
    className="full-image"
    style={{
      transform:
        "translate3d(0px, 0px, 0px) scale3d(1.11977, 1.11977, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
      transformStyle: "preserve-3d",
      willChange: "transform"
    }}
  >
    <div className="banner-background-project" />
    <img
      srcSet="https://images.aircharterservice.com/blog/a-private-jet-parked-on-the-tarmac-with-its-stairs-down-header.jpg"
      loading="lazy"
      sizes="100vw"
      alt="yacht"
      src="https://images.aircharterservice.com/blog/a-private-jet-parked-on-the-tarmac-with-its-stairs-down-header.jpg"
      className="image-inner"
    />
  </div>
  <div className="flex-vertical">
    <div className="block-banner-top">
      <div className="left-description inner-page">
        <div className="bottom-block">
          <h1
            data-w-id="8225ce5a-5dd5-e910-d9c5-c24e23587012"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
            className="text-color-white text-left"
          >
            
Experience the enchantment of a Sunset Flight
          </h1>
          <div
            data-w-id="8225ce5a-5dd5-e910-d9c5-c24e23587014"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
            className="description-banner"
          >
            {/* <p className="paragraph-large text-white text-left">
              Each of our services is designed to cater to diverse preferences,
              ensuring that your boat or yacht rental experience with us is not
              just a journey but a personalized.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div className="section">
  <div className="base-container w-container">
    <div className="w-layout-grid service-wrapper">
      <div
        id="w-node-_50ad28b0-be2a-bbb3-9d61-7c7c3f8a3fe7-85f80769"
        data-w-id="50ad28b0-be2a-bbb3-9d61-7c7c3f8a3fe7"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="our-service-item"
      >
        <div className="service-item-block">
          <img
            src={p1}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1279px) 97vw, 1170px"
            srcSet={p1}
            alt="yacht"
            className="service-item-image"
          />
        </div>
        <div className="service-item-content">
          <div className="service-title-wrapper">
           
            <h5 className="text-primary-color">AIRCRAFT CHARTERS</h5>
          </div>
          <p className="service-item-description">
          We offer our line production services for aerial sequences as well as charter services to the crew across the globe.
          </p>
        </div>
      </div>
      <div
        id="w-node-_50ad28b0-be2a-bbb3-9d61-7c7c3f8a3ff5-85f80769"
        data-w-id="50ad28b0-be2a-bbb3-9d61-7c7c3f8a3ff5"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="our-service-item"
      >
        <div className="service-item-block">
          <img
            src={p2}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1279px) 97vw, 1170px"
            srcSet={p2}
            alt="team"
            className="service-item-image"
          />
        </div>
        <div className="service-item-content">
          <div className="service-title-wrapper">
           
            <h5 className="text-primary-color">AERIAL CINEMATOGRAPHY</h5>
          </div>
          <p className="service-item-description">
          Our team of experienced drone operators and aircraft pilots have captured stunning images across the globe. Let's fly for you.
          </p>
        </div>
      </div>
      <div
        id="w-node-_50ad28b0-be2a-bbb3-9d61-7c7c3f8a4003-85f80769"
        data-w-id="50ad28b0-be2a-bbb3-9d61-7c7c3f8a4003"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="our-service-item"
      >
        <div className="service-item-block">
          <img
            src={p3}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1279px) 97vw, 1170px"
            srcSet={p3}
            alt="team"
            className="service-item-image"
          />
        </div>
        <div className="service-item-content">
          <div className="service-title-wrapper">
            
            <h5 className="text-primary-color">AERIAL SHOOTING</h5>
          </div>
          <p className="service-item-description">
          No matter how big or small your requirement is, our team of experienced pilots and engineers will offer you the right advice
FOR aerial shoot from different angles.

          </p>
        </div>
      </div>
      <div
        id="w-node-_50ad28b0-be2a-bbb3-9d61-7c7c3f8a4011-85f80769"
        data-w-id="50ad28b0-be2a-bbb3-9d61-7c7c3f8a4011"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="our-service-item"
      >
        <div className="service-item-block">
          <img
            src={p4}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1279px) 97vw, 1170px"
            srcSet={p4}
            alt="yacht"
            className="service-item-image"
          />
        </div>
        <div className="service-item-content">
          <div className="service-title-wrapper">
           
            <h5 className="text-primary-color">AERIAL ADVERTISING</h5>
          </div>
          <p className="service-item-description">
          Create a massive impression amongst public as your brand flies over your city with our aerial advertisement services.
          </p>
        </div>
      </div>
      <div
        id="w-node-_50ad28b0-be2a-bbb3-9d61-7c7c3f8a401f-85f80769"
        data-w-id="50ad28b0-be2a-bbb3-9d61-7c7c3f8a401f"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="our-service-item"
      >
        <div className="service-item-block">
          <img
            src={p5}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1279px) 97vw, 1170px"
            srcSet={p5}
            alt="team"
            className="service-item-image"
          />
        </div>
        <div className="service-item-content">
          <div className="service-title-wrapper">
           
            <h5 className="text-primary-color">AIRCRAFT SCALE MODEL</h5>
          </div>
          <p className="service-item-description">
          We provide aircraft scale model.A scale model is a physical model which is geometrically similar to an object (known as the prototype). Scale models are generally smaller than large prototypes such as helicopter, private jet. 
          </p>
        </div>
      </div>
      <div
        id="w-node-_50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d-85f80769"
        data-w-id="50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="our-service-item"
      >
        <div className="service-item-block">
          <img
            src={p6}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1279px) 97vw, 1170px"
            srcSet={p6}
            alt="team member"
            className="service-item-image"
          />
        </div>
        <div className="service-item-content">
          <div className="service-title-wrapper">
           
            <h5 className="text-primary-color">AERIAL ADVENTURES & SITE SCENES.</h5>
          </div>
          <p className="service-item-description">
          We provide these facilities to view hilly area, national parks, caves, city view & waterfall top views.
          </p>
        </div>
      </div>
      <div
        id="w-node-_50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d-85f80769"
        data-w-id="50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="our-service-item"
      >
        <div className="service-item-block">
          <img
            src={p7}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1279px) 97vw, 1170px"
            srcSet={p7}
            alt="team member"
            className="service-item-image"
          />
        </div>
        <div className="service-item-content">
          <div className="service-title-wrapper">
          
            <h5 className="text-primary-color">FLOWER DROPPING OR SHOWERING</h5>
          </div>
          <p className="service-item-description">
          We are dedicated to offering quality Flower Dropping services to all Event. We provide Flower Dropping and Flower Showering Helicopter Service all across India.
          </p>
        </div>
      </div>
     
      <div
        id="w-node-_50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d-85f80769"
        data-w-id="50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="our-service-item"
      >
        <div className="service-item-block">
          <img
            src={p9}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1279px) 97vw, 1170px"
            srcSet={p9}
            alt="team member"
            className="service-item-image"
          />
        </div>
        <div className="service-item-content">
          <div className="service-title-wrapper">
            
            <h5 className="text-primary-color">HELIPAD & AIRPORT RENTAL</h5>
          </div>
          <p className="service-item-description">
          Use our helipads & airports for recce, team travel or even as a location for your shoots.
          </p>
        </div>
      </div>
      <div
        id="w-node-_50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d-85f80769"
        data-w-id="50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="our-service-item"
      >
        <div className="service-item-block">
          <img
            src={p10}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1279px) 97vw, 1170px"
            srcSet={p10}
            alt="team member"
            className="service-item-image"
          />
        </div>
        <div className="service-item-content">
          <div className="service-title-wrapper">
            
            <h5 className="text-primary-color">JOY RIDE</h5>
          </div>
          <p className="service-item-description">
          A joy ride above the city is an unforgettable way to experience the breathtaking beauty of cities and its surrounding areas. Bring your friends and family to have fun and enjoyment. Also make your loved one’s special days like birthdays, anniversary as a memorable one by taking them for a fun and exciting joy ride in the clouds
          </p>
        </div>
      </div>
      <div
        id="w-node-_50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d-85f80769"
        data-w-id="50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="our-service-item"
      >
        <div className="service-item-block">
          <img
            src={p11}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1279px) 97vw, 1170px"
            srcSet={p11}
            alt="team member"
            className="service-item-image"
          />
        </div>
        <div className="service-item-content">
          <div className="service-title-wrapper">
            
            <h5 className="text-primary-color">PHOTOSHOOT</h5>
          </div>
          <p className="service-item-description">
          use our aircrafts for personal photoshoot & wedding shoot.
          </p>
        </div>
      </div>
      <div
        id="w-node-_50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d-85f80769"
        data-w-id="50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="our-service-item"
      >
        <div className="service-item-block">
          <img
            src={p12}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1279px) 97vw, 1170px"
            srcSet={p12}
            alt="team member"
            className="service-item-image"
          />
        </div>
        <div className="service-item-content">
          <div className="service-title-wrapper">
           
            <h5 className="text-primary-color">RELIGIOUS YATRA</h5>
          </div>
          <p className="service-item-description">
          Uttarakhand Tourism Char Dham Yatra by air Destinations: Haridwar, Yamunotri, Gangotri, Kedarnath, Badrinath & amarnath Duration: 4 nights, 5 days
          </p>
        </div>
      </div>
      <div
        id="w-node-_50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d-85f80769"
        data-w-id="50ad28b0-be2a-bbb3-9d61-7c7c3f8a402d"
        style={{
          opacity: 1,
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
        className="our-service-item"
      >
        <div className="service-item-block">
          <img
            src={p13}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 1279px) 97vw, 1170px"
            srcSet={p13}
            alt="team member"
            className="service-item-image"
          />
        </div>
        <div className="service-item-content">
          <div className="service-title-wrapper">
            
            <h5 className="text-primary-color">HELICOPTERS AND CHARTERS FOR FILM SHOOTING</h5>
          </div>
          <p className="service-item-description">
          We provide charters and helicopters for aerial shots in movies typically capture the ground below, they are perfect for shooting the different scenes provided by epic helicopter fleets as per customer requirements.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Service
