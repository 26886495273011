import React from 'react'
import Logo from "../Assets/LOGO.png"
import { IoCallSharp } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";

const Footer = () => {
  return (
    <div>
      <section className="footer">
  <div className="base-container w-container">
    <div
      data-w-id="b23ef212-3ed4-3e93-9585-7d6d95e1b0a7"
      className="footer-top-wrapper"
      style={{
        opacity: 1,
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
    >
      <div className="footer-brand-block">
        <div className="footer-logo-wrapper">
          <a href="/" className="footer-logo-holder w-nav-brand">
            <img
              src={Logo}
              loading="lazy"
              alt="logo"
              height={30}
              className="logo-footer"
            />
          </a>
          <p className="footer-small-description">
          Charter your private plane or helicopter with Rent Air and Sky and let the clouds carry you to a world of unmatched beauty.
          </p>
        </div>
        <div className="footer-social-icons-wrapper-2">
          <a
            href="https://www.facebook.com/"
            target="_blank"
            className="footer-social-icon"
          >
            
          </a>
          <a
            href="https://www.instagram.com/"
            target="_blank"
            className="footer-social-icon"
          >
            
          </a>
          <a
            href="https://twitter.com/"
            target="_blank"
            className="footer-social-icon"
          >
            
          </a>
        </div>
      </div>
      <div className="footer-links-wrapper">
        <p className="footer-title">Links</p>
        <div className="footer-links-columns-wrapper-large padding-top-small">
          <div className="footer-links-wrapper half-width" style={{width:"100%"}}>
            <a href="/" className="footer-link no-top-padding">
            Home
            </a>
            <a href="/aboutus" className="footer-link">
            About Us
            </a>
            <a href="/Service" className="footer-link">
            Facility
            </a>
            <a href="/Servicees" className="footer-link">
            Service
            </a>
            <a href="/Contectus" className="footer-link">
            Contact Us
            </a>
          </div>
          {/* <div className="footer-links-wrapper half-width">
            <a href="/shop" className="footer-link no-top-padding">
              Shop
            </a>
            <a href="/testimonials" className="footer-link">
              Testimonials
            </a>
            <a href="/services" className="footer-link">
              Services
            </a>
            <a
              href="/contact-us"
              aria-current="page"
              className="footer-link w--current"
            >
              Contact Us
            </a>
          </div> */}
        </div>
      </div>
      <div className="footer-links-wrapper tablet-card-wrapper-small">
        <p className="footer-title margin-bottom-small">Contact  details</p>
        <div className="flex-vertical-card small-gap">
        <div className="footer-links-wrapper " style={{width:"100%"}}>
           <p className="footer-link"><IoCallSharp />:<span style={{fontSize:"12px"}}>+91-9324598211/9820447027</span></p>
           <p className="footer-link"><IoMdMail />:<span style={{fontSize:"12px"}}>info@aerofoilaviation.in</span></p>
           <p className="footer-link"> <MdLocationOn />:<span style={{fontSize:"12px"}}>FLAT NO-504, GAURAV CITY E-3 CHS LTD, NR CINE MAX
MIRA BHAINDAR ROAD BEVERLY PARK, MIRA ROAD
EAST, Thane, Maharashtra, 401107</span></p>
           {/* <p></p>
            <a href="/yachts" className="footer-link">
              Yachts
            </a>
            <a href="/events" className="footer-link">
              Events
            </a>
            <a href="/about-us" className="footer-link">
              About Us
            </a> */}
          </div>
       
        </div>
      </div>
      <div className="footer-newsletter-wrapper fixed-width-medium-center">
        <p className="footer-title margin-bottom-small"> Location</p>
        <div className="full-width subscribe w-form">
         
         <div style={{borderRadius:"25px"}}>
              <iframe style={{borderRadius:"25px"}}
  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15063.162331058396!2d72.8774621!3d19.2914711!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b17e05941b55%3A0x55c57ef19e2d947d!2sGaurav%20city%20complex!5e0!3m2!1sen!2sin!4v1706606726312!5m2!1sen!2sin "/>
              </div>
          
        </div>
      </div>
    </div>
    <div
      data-w-id="b23ef212-3ed4-3e93-9585-7d6d95e1b0ea"
      className="footer-bottom-wrapper"
      style={{
        opacity: 1,
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
    >
      <div className="footer-copyright">
        All Rights Reserved by Aerofoil Aviation..{" "}
       
      </div>
      <div className="footer-rights-wrapper">
       
        <div className="footer-rights">
         
          <a
            href="https://skdm.in/"
            target="_blank"
            className="footer-copyright-link"
          >
           Developed by Shree Krishna Digital
          </a>
        </div>
      </div>
    </div>
  </div>
  <div className="global">
    <div className="html w-embed">
      <style
        dangerouslySetInnerHTML={{
          __html: "\nhtml, body {\n\toverscroll-behavior: none;\n}\n"
        }}
      />
    </div>
  </div>
</section>

    </div>
  )
}

export default Footer
